import { createBrowserRouter, useLocation } from "react-router-dom";
import React, { Suspense } from "react";

import { Home } from "./views/main-app/views/home/Home";
import { Navigate } from "./navigation";
import { Login } from "./views/login/Login";
import { MainApp } from "./views/main-app/MainApp";
import { RequireAuth } from "./auth";
import { Students } from "./views/main-app/views/students/Students";
import { StudentDetails } from "./views/main-app/views/students/student-details/StudentDetails";
import { AddStudent } from "./views/main-app/views/students/add-edit-student/AddStudent";
import { EditStudent } from "./views/main-app/views/students/add-edit-student/EditStudent";
import { CourseDetails } from "./views/main-app/views/students/courses/course-details/CourseDetails";
import { AddCourse } from "./views/main-app/views/students/courses/add-edit-course/AddCourse";
import { AddCourseTask } from "./views/main-app/views/students/courses/tasks/add-edit-task/AddCourseTask";
import { EditCourseTask } from "./views/main-app/views/students/courses/tasks/add-edit-task/EditCourseTask";
import { TaskDetails } from "./views/main-app/views/students/courses/tasks/TaskDetails";
import { EditCourse } from "./views/main-app/views/students/courses/add-edit-course/EditCourse";
import { ActivityLog } from "./views/main-app/views/activity-log/ActivityLog";
import { CompleteTask } from "./views/main-app/views/students/courses/tasks/complete-task/CompleteTask";

export const browserRouter = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/",
    element: <MainApp />,
    children: [
      {
        index: true,
        element: <Navigate to="home" />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "activity-log",
        element: <ActivityLog />,
      },
      {
        path: "students",
        children: [
          {
            index: true,
            element: <Students />,
          },
          {
            path: "add",
            element: <AddStudent />,
          },
          {
            path: ":studentId",
            element: <StudentDetails />,
          },
          {
            path: ":studentId/edit",
            element: <EditStudent />,
          },
          {
            path: ":studentId/courses/add",
            element: <AddCourse />,
          },
          {
            path: ":studentId/courses/:courseId",
            element: <CourseDetails />,
          },
          {
            path: ":studentId/courses/:courseId/edit",
            element: <EditCourse />,
          },
          {
            path: ":studentId/courses/:courseId/tasks/add",
            element: <AddCourseTask />,
          },
          {
            path: ":studentId/courses/:courseId/tasks/:taskId",
            element: <TaskDetails />,
          },
          {
            path: ":studentId/courses/:courseId/tasks/:taskId/edit",
            element: <EditCourseTask />,
          },
          {
            path: ":studentId/courses/:courseId/tasks/:taskId/complete",
            element: <CompleteTask />,
          },
        ],
      },
      {
        path: "*",
        element: <div>Not Found</div>,
        // title: "",
        // isNestedView: false,
      },
    ],
  },
]);

export const useIsNestedView = () => {
  const location = useLocation();

  return location.pathname.split("/").length > 2;
};
