import React from "react";

import { List, ListItem, ListItemButton, ListItemContent } from "@mui/joy";

import { Link as RouterLink, useLocation } from "react-router-dom";
import { ActivityLogIcon, HomeIcon, StudentsIcon } from "../icons";

export const BottomNavigation: React.FC = () => {
  const location = useLocation();

  return (
    <List
      id="tab-bar"
      orientation="horizontal"
      sx={{
        width: "100dvw",
        backgroundColor: "background.body",
        justifyContent: "space-evenly",
      }}
    >
      <ListItem>
        <ListItemButton
          component={RouterLink}
          to="/home"
          selected={location.pathname.startsWith("/home")}
          sx={{ flexDirection: "column", "--Button-gap": 0 }}
        >
          <HomeIcon />
          <ListItemContent>Home</ListItemContent>
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton
          component={RouterLink}
          to={"/activity-log"}
          selected={location.pathname.startsWith("/activity-log")}
          sx={{ flexDirection: "column", "--Button-gap": 0 }}
        >
          <ActivityLogIcon />
          <ListItemContent>Activity Log</ListItemContent>
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton
          component={RouterLink}
          to={"/students"}
          selected={location.pathname.startsWith("/students")}
          sx={{ flexDirection: "column", "--Button-gap": 0 }}
        >
          <StudentsIcon />
          <ListItemContent>Students</ListItemContent>
        </ListItemButton>
      </ListItem>
    </List>
  );
};
